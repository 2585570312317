<template>
  <div v-loading="loading" :element-loading-text="'加载中'" class="exam">
    <div class="exam-con">
      <div class="top-content" :style="showAnswerCard ? 'width: 100%;' : ''">
        <div class="top-left">
          <img class="item-image" src="@/assets/img/Home/question/bank-icon.png">
          <div class="kcName ellipsis">{{ kcName }}</div>
        </div>
        <div v-if="showReport" class="report-btn" :class="isReportDisable ? 'report-disable' : ''"
          @click="showAnswerChange">
          生成练习报告
        </div>
      </div>
      <div class="block">
        <div>
          <div class="question-wrap">
            <div v-for="(item, index) of questionList" ref="queCarBox" :key="index">
              <template v-if="currentQuestion == index">
                <que-car ref="queCar" :paper-id="kId" :is-purchase="true" :start="true" :detail="item"
                  :detail-id="index" :is-random="true" :is-ti-ku="true" :is-submited="item.isSubmit" :is-points="false"
                  @task="showSubmit" />
                <div class="bot-btn">
                  <span :class="disablePrev ? 'disable' : ''" @click="handlePrev(index)">
                    <i class="iconfont icon-xiangzuo" />上一题
                  </span>
                  <span :class="disableNext ? 'disable' : ''" @click="handleNext(index)">
                    下一题<i class="iconfont icon-xiangyou" />
                  </span>
                </div>
              </template>
            </div>
          </div>
          <!-- 右侧 -->
          <div v-if="showAnswerCard" class="shiti-function">
            <div class="timebox">
              <i class="iconfont icon-daojishi" />
              答题时间
              <span v-if="time" class="countdown">
                {{ time.hours > 9 ? time.hours : '0' + time.hours }}:{{
                  time.minutes > 9 ? time.minutes : '0' + time.minutes
                }}:{{ time.seconds > 9 ? time.seconds : '0' + time.seconds }}
              </span>
            </div>
            <!-- 答题卡 -->
            <div class="sheet numberPannel m-t-20">
              <div class="sheet-header">
                <div class="flex">
                  <span class="num">
                    <i class="iconfont icon-dian" style="color: #38c25f" />
                    正确
                    {{ answerData.ZQsubjectName }}
                  </span>
                  <span class="num">
                    <i class="iconfont icon-dian" style="color: #ff5d51" />
                    错误
                    {{ answerData.SubjectName - answerData.ZQsubjectName - answerData.NosubjectName }}
                  </span>
                  <span v-if="examType == 'try'" class="num">
                    <i class="iconfont icon-dian" style="color: #666" />未答
                    {{ answerData.NosubjectName }}
                  </span>
                  <span class="num" style="margin-left: 10px">正确率:{{ answerData.accuracy }}</span>
                </div>
              </div>
              <!-- 试题类型 -->
              <div class="sheet-block">
                <div v-for="(item, shiIndex) of answerData.arrayList" :key="shiIndex" class="t-item">
                  <div class="t-item" :class="answerType(item)" @click="jumpShiTi(shiIndex)">
                    {{ shiIndex + 1 }}
                  </div>
                </div>
              </div>
              <!-- 交卷 -->
              <!-- <div class="btnlist" @click="answerAgin">重新答题</div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import queCar from './queCar.vue'
import {
  getTryQuestionList,
  getAnswerQuestListByMode,
  getFallibleAnswerQuestListByMode,
  getWrongAnswerQuestListByMode,
  addPcSubjectAnswer,
  addFalliblePcSubjectAnswer,
  addWrongPcSubjectAnswer,
  getPcSubjectAnswer
} from '@/api/question'
import { getInfo } from '@/api/cookies'
export default {
  components: { queCar },
  data() {
    return {
      kId: '',
      kcName: '',
      loading: false,
      IsPurchase: false,
      questionList: [], // 未处理前的题库列表
      newQuestionList: [], // 练习模式只提交答题过的
      subList: [],
      showReport: false, // 是否显示生成练习报告按钮
      currentQuestion: 0, // 当前的题
      examType: '', // 试做try
      type: '', // 0:客观题题库/1:易错错题库/2:个人错题库
      userInfo: {},
      start: true, // 是否计时
      timeEnd: '',
      time: {
        hours: 0,
        minutes: 0,
        seconds: 0
      },
      /* 做题数 */
      complete: 0,
      showAnswerCard: false,
      answerData: {}
    }
  },
  computed: {
    disablePrev() {
      if (this.currentQuestion == 0) {
        return true
      }
      return false
    },
    disableNext() {
      // 随机模式
      if (this.examType !== 'try') {
        // 已提交判断答题卡列表长度禁用
        if (this.showAnswerCard) {
          if (this.currentQuestion === this.answerData.arrayList.length - 1) {
            return true
          }
        } else {
          // 当前题是否已选中
          if (!this.questionList[this.currentQuestion].isSubmit) {
            return true
          }
          // 随机模式点击最后一道题禁用
          if (this.currentQuestion == this.questionList.length - 1) {
            return true
          }
        }
      }
      return false
    },
    isReportDisable() {
      if (this.questionList[0].isSubmit) return false
      return true
    }
  },
  async created() {
    this.kId = this.$route.query.kId
    this.kcName = this.$route.query.kcName
    this.examType = this.$route.query.examType
    this.type = this.$route.query.type
    this.userInfo = getInfo()
    if (this.examType == 'try') {
      // 试做模式
      await this.getTryQuestion()
    } else {
      // 随机模式
      await this.getAnswerQuestList()
      this.timeInterval()
    }
  },
  methods: {
    // 试做试题列表
    async getTryQuestion() {
      this.loading = true
      const { data } = await getTryQuestionList({
        kId: this.kId,
        num: 5
      })
      this.questionList = data
      await this.splicing(data, false)
      this.loading = false
    },
    // 查询提交报告
    async getAnswer(recordId) {
      const { data } = await getPcSubjectAnswer({ recordId })
      this.answerData = data
      this.showAnswerCard = true
    },
    // 随机试题列表
    async getAnswerQuestList() {
      this.loading = true
      let res = {}
      if (this.type == 0) {
        // 客观题-获取试题列表
        res = await getAnswerQuestListByMode({
          kId: this.kId,
          userId: this.userInfo && this.userInfo.id,
          mode: 1
        })
      } else if (this.type == 1) {
        // 易错题-获取试题列表
        res = await getFallibleAnswerQuestListByMode({
          kId: this.kId,
          userId: this.userInfo && this.userInfo.id,
          mode: 1
        })
      } else {
        // 错题库-获取试题列表
        res = await getWrongAnswerQuestListByMode({
          kId: this.kId,
          userId: this.userInfo && this.userInfo.id,
          mode: 1
        })
      }
      this.questionList = res.data
      await this.splicing(res.data, false)
      this.loading = false
      this.showReport = true
    },
    splicing(list, ans) {
      this.TiXing = [0, 0, 0, 0, 0, 0]

      /* ans  是否是提交显示的 */
      let shiti = []
      for (const item of list) {
        item.questionType = Number(item.questionType)
        if (
          item.questionType == 0 ||
          item.questionType == 1 ||
          item.questionType == 6
        ) {
          const data = JSON.parse(item.questionOption)
          const sub = []
          for (const i in data) {
            const val = {
              val: data[i],
              num: String.fromCharCode(Number(i) + 65)
            }
            sub.push(val)
          }
          item.optionList = sub
          if (ans && (item.questionType == 1 || item.questionType == 6)) {
            item.questionUserAnswer = []
          }
          if (!ans && (item.questionType == 1 || item.questionType == 6)) {
            if (item.questionUserAnswer) {
              item.questionUserAnswer = item.questionUserAnswer.split(',')
            } else {
              item.questionUserAnswer = []
            }
          }
          shiti.push(item)
        } else if (item.questionType == 2) {
          item.optionList = [
            { val: '对', num: 'A' },
            { val: '错', num: 'B' }
          ]
          shiti.push(item)
        } else if (item.questionType == 5) {
          if (item.childSubject) {
            item.childSubject.forEach((chil, chilIndex) => {
              chil.questionType = Number(chil.questionType)
              // 添加题号字段
              chil.indexVal = Number(chilIndex) + 1
              if (
                chil.questionType == 0 ||
                chil.questionType == 1 ||
                chil.questionType == 6
              ) {
                const data = JSON.parse(chil.questionOption)
                const sub = []
                for (const i in data) {
                  const val = {
                    val: data[i],
                    num: String.fromCharCode(Number(i) + 65)
                  }
                  sub.push(val)
                }
                chil.optionList = sub
                if (ans && (chil.questionType == 1 || chil.questionType == 6)) {
                  chil.questionUserAnswer = []
                }
                if (
                  !ans &&
                  (chil.questionType == 1 || chil.questionType == 6)
                ) {
                  if (chil.questionUserAnswer) {
                    chil.questionUserAnswer =
                      chil.questionUserAnswer.split(',')
                  } else {
                    chil.questionUserAnswer = []
                  }
                }
              } else if (chil.questionType == 2) {
                chil.optionList = [
                  { val: '对', num: 'A' },
                  { val: '错', num: 'B' }
                ]
              }
            })
            shiti = shiti.concat(item.childSubject)
          }
        } else {
          shiti.push(item)
        }
      }
      this.subList = shiti
      // 添加题号字段
      for (const i in list) {
        const item = list[i]
        item.indexVal = Number(i) + 1
      }
    },
    // 下一题
    handleNext(val) {
      if (this.disableNext) return
      if (this.currentQuestion == this.questionList.length - 1) {
        if (this.examType == 'try') {
          this.$confirm('试做已结束，购买后可继续练习', '提示', {
            confirmButtonText: '去购买',
            cancelButtonText: '关闭',
            type: 'warning'
          })
            .then(() => {
              this.goOrder()
            })
            .catch(() => {
              this.$router.go(-1)
            })
          return
        }
        return
      }
      this.currentQuestion = val + 1
      window.scrollTo(0, 0)
    },
    // 上一题
    handlePrev(val) {
      if (this.currentQuestion == 0) return
      this.currentQuestion = val - 1
      window.scrollTo(0, 0)
    },
    // 展示解析
    showSubmit(val) {
      this.questionList.forEach((element, index) => {
        if (index === val) {
          this.$set(element, 'isSubmit', true)
          this.$set(element, 'questionUserAnswer', element.questionUserAnswer)
          // 练习模式只提交已经答过的题
          if (element.isSubmit) {
            this.newQuestionList.push(element)
          }
        }
      })
    },
    // 去购买
    goOrder() {
      if (this.userInfo) {
        this.$router.push(
          `/orders/classOrder?classId=${this.$route.query.classId}`
        )
      } else {
        Vue.prototype.goLoginView(true)
      }
    },
    // 提交
    async showAnswerChange() {
      if (this.isReportDisable) return
      const that = this
      if (this.userInfo != null) {
        this.$confirm('确认交卷吗？', '提示', {
          confirmButtonText: '提交',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            that.postEvery()
          })
      }
      this.canClick = true
      this.ifNew = false
      this.$nextTick(() => {
        this.ifNew = true
      })
      this.$forceUpdate()
    },
    /* 重新答题 */
    answerAgin() {
      window.location.reload()
    },
    // 提交答案
    async postEvery() {
      this.loading = true
      const times =
        (await (this.time.hours > 0 ? this.time.hours * 60 * 60 : 0)) +
        (this.time.minutes > 0 ? this.time.minutes * 60 : 0) +
        this.time.seconds
      const data = await {
        questionSubjectPaperVoList: this.examType == 'try' ? this.questionList : this.newQuestionList,
        answerTimes: times || 0,
        courseId: this.kId,
        mode: 1
      }
      // 提交试卷
      let res = {}
      if (this.type == 0) {
        res = await addPcSubjectAnswer(data)
      } else if (this.type == 1) {
        res = await addFalliblePcSubjectAnswer(data)
      } else {
        res = await addWrongPcSubjectAnswer(data)
      }
      if (res.code == 0) {
        clearTimeout(this.timeEnd) // 清除
        await this.getAnswer(res.data)
        this.questionList.forEach(element => {
          this.$set(element, 'isSubmit', true)
        })
        this.loading = false
        this.showReport = false
      } else {
        this.loading = false;
        return
      }
    },
    // 答题卡颜色
    answerType(item) {
      if (item.isCorrect == 0) {
        return 't-error'
      } else if (item.isCorrect == 1) {
        return 't-sucess'
      } else {
        return 't-no'
      }
    },
    // 计时器
    timeInterval() {
      this.timeEnd = setInterval(() => {
        // 回掉函数开始计时了
        this.time.seconds++
        if (this.time.seconds == 60) {
          this.time.seconds = 0
          this.time.minutes++
        }
        if (this.time.minutes == 60) {
          this.time.minutes = 0
          this.time.hours++
        }
      }, 1000)
    },
    // 跳转试题
    jumpShiTi(id) {
      this.currentQuestion = id
    }
  }
}
</script>

<style lang="less" scoped>
.exam-con {
  width: 1220px;
  margin: 0px auto;

  .top-content {
    width: 880px;
    height: 60px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 0 49px 0 20px;

    .top-left {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;

      .item-image {
        width: 51px;
        height: 51px;
        margin-right: 12px;
      }

      .kcName {
        max-width: 600px;
        overflow: hidden;
      }
    }

    .report-btn {
      width: 109px;
      height: 26px;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 1px solid #ff5e51;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #ff5e51;
      text-align: center;
      line-height: 23px;
      cursor: pointer;
    }

    .report-disable {
      border-color: #ccc;
      color: #ccc;
      cursor: not-allowed;
    }
  }

  .shijuan-block {
    position: relative;
    width: 1200px;
    margin: auto;
    padding: 20px 30px;
    margin-bottom: 20px;
    background-color: #fff;

    margin-top: 30px;

    .shijuan-type {
      display: flex;
      align-items: center;
      color: #333333;
      font-size: 14px;

      i {
        font-size: 20px;
        margin-right: 5px;
      }
    }

    .shijuan-title {
      text-align: center;
      font-size: 22px;
      margin: 0;
      padding: 2px 0px 27px;
      border-bottom: 1px dashed #e0e3e9;

      .grade {
        position: absolute;
        left: 40px;
        top: 0px;
        width: 150px;
        height: 80px;
        font-size: 50px;
        font-family: qiantumakeshouxieti;
        font-weight: 400;
        line-height: 80px;
        color: #ff5104;
      }
    }

    .content {
      font-size: 16px;
      color: #666;
      line-height: 2;
      padding-left: 60px;
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  .warning {
    background-color: #fff9e9;
    border: 1px dashed #ffeec4;
    text-align: center;
    line-height: 2.3;
    font-size: 16px;
    color: #ed7321;
  }

  .block {
    width: 1220px;
    margin: auto;
    overflow: hidden;
    margin-bottom: 68px;

    .question-wrap {
      float: left;
      width: 880px;
      position: relative;
      background: #ffffff;
      border-radius: 8px;

      .bot-btn {
        width: 100%;
        height: 50px;
        border-top: #eeeeee 1px solid;
        background: #ffffff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        cursor: pointer;

        .disable {
          color: #999999;
          cursor: not-allowed;
        }

        >span {
          width: 320px;
          height: 50px;
          line-height: 50px;
          text-align: center;

          >.iconfont {
            font-size: 12px;
            margin: 0 4px;
          }
        }
      }
    }

    // <!-- 左侧 -->
    .shiti-function {
      width: 320px;
      float: right;
      min-height: 300px;

      /* 答题时间 */
      .timebox {
        width: 100%;
        height: 80px;
        background: #ffffff;
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        font-size: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        line-height: 80px;
        padding-left: 31px;

        .iconfont {
          font-size: 18px;
          margin-right: 9px;
        }

        .countdown {
          color: #ff5d51;
        }

        .red {
          color: red !important;
        }
      }

      .sheet {
        width: 100%;
        background-color: #fff;
        padding: 0 0 20px;

        .sheet-header {
          width: 100%;
          line-height: 58px;
          height: 58px;
          border-bottom: 1px solid #eee;
          display: flex;
          justify-content: start;
          align-items: center;
          position: relative;
          padding-left: 10px;

          .title {
            font-size: 16px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            line-height: 16px;
            flex-shrink: 0;
            position: relative;

            .icon {
              position: absolute;
              left: 50%;
              transform: translate(-50%);
              top: 40px;
              width: 18px;
              height: 6px;
            }
          }

          .num {
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            line-height: 14px;

            .iconfont {
              font-size: 8px;
              margin-left: 12px;
              margin-right: 4px;
            }
          }
        }

        .sheet-block {
          padding: 15px 20px 15px;
          max-height: 360px;
          overflow-y: auto;
          display: flex;
          flex-wrap: wrap;

          .t-item {
            width: 40px;
            height: 30px;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #666;
            line-height: 30px;
            text-align: center;
            cursor: pointer;
            margin: 6px;

            .t-item {
              border: 1px solid #eeeeee;
              line-height: 28px;
              margin: 0px;
            }

            &:hover {
              background-color: #ee491f;
              color: #f5deb3;
            }
          }

          .t-on {
            border: 1px solid #38c25f;
            background: #38c25f;
            color: #ffffff;
          }

          .t-error {
            border: 1px solid #ff5d51;
            background: #ff5d51;
            color: #ffffff;
          }

          .t-no {
            border: 1px solid #666;
            background: #666;
            color: #ffffff;
          }

          .t-sucess {
            border: 1px solid #38c25f;
            background: #38c25f;
            color: #ffffff;
          }
        }

        .btnlist {
          width: 200px;
          height: 40px;
          line-height: 40px;
          margin: 83px auto 20px;
        }
      }
    }

    /*  */
    .fixed-card {
      position: fixed;
      top: 116px;
      right: calc(50% - 610px);
    }
  }
}
</style>
